import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import(/* webpackChunkName: "ReleasesNew" */ '../views/ReleasesNew.vue')
    },
    {
        path: '/releases',
        name: 'Releases',
        props: () => ({
            id: 'all-releases',
            h1: 'releases all',
            meta_name: 'all releases',
            meta_description: 'all releases',
            canonical: '/releases',
            search_placeholder: 'search from all releases',
            query_params: {},
            hide_dialogs: {},
            actionBtn: {
                to: '/releases/new',
                label: 'add new release',
                icon: 'mdi-plus-outline',
            },
            default_item_component: 'ItemPicture', //default view component defines the look of the list. Can be changed
            pictureListingLabel: 'format'
        }),
        component: () => import(/* webpackChunkName: "Releases" */ '../views/Releases.vue')
    },
    {
        path: '/releases/4k',
        name: 'Releases4K',
        props: () => ({
            id: '4k-releases',
            h1: '4k releases',
            meta_name: '4k releases',
            meta_description: '4k releases',
            search_placeholder: 'search from 4k releases',
            query_params: {
                fo: [7,11]
            },
            hide_dialogs: {formats:true},
            actionBtn: {
                to: '/releases/new',
                label: 'add new release',
                icon: 'mdi-plus-outline',
            },
            canonical: '/releases/4k',
            default_item_component: 'ItemPicture', //default view component defines the look of the list. Can be changed
            pictureListingLabel: 'label'
        }),
        component: () => import(/* webpackChunkName: "Releases" */ '../views/Releases.vue')
    },
    {
        path: '/releases/bluray',
        name: 'ReleasesBluray',
        props: () => ({
            id: 'blu-ray-releases',
            h1: 'Blu-ray releases',
            meta_name: 'Blu-ray releases',
            meta_description: 'Blu-ray releases',
            search_placeholder: 'search from bluray releases',
            canonical: '/releases/bluray',
            query_params: {
                fo: [1, 5]
            },
            hide_dialogs: {formats: true},
            actionBtn: {
                to: '/releases/new',
                label: 'add new release',
                icon: 'mdi-plus-outline',
            },
            default_item_component: 'ItemPicture', //default view component defines the look of the list. Can be changed
            pictureListingLabel: 'label'
        }),
        component: () => import(/* webpackChunkName: "Releases" */ '../views/Releases.vue')
    },
    {
        path: '/releases/dvd',
        name: 'ReleasesDVD',
        props: () => ({
            id: 'dvd-releases',
            h1: 'DVD releases',
            meta_name: 'DVD releases',
            meta_description: 'DVD releases',
            search_placeholder: 'search from dvd releases',
            canonical: '/releases/dvd',
            query_params: {
                fo: [3, 4]
            },
            hide_dialogs: {formats: true},
            actionBtn: {
                to: '/releases/new',
                label: 'add new release',
                icon: 'mdi-plus-outline',
            },
            default_item_component: 'ItemPicture', //default view component defines the look of the list. Can be changed
            pictureListingLabel: 'label'
        }),
        component: () => import(/* webpackChunkName: "Releases" */ '../views/Releases.vue')
    },
    {
        path: '/releases/videotapes',
        name: 'ReleasesVideotapes',
        props: () => ({
            id: 'vhs-releases',
            h1: 'videotapes',
            meta_name: 'videotapes',
            meta_description: 'videotapes',
            search_placeholder: 'search from videotape releases',
            canonical: '/releases/videotapes',
            query_params: {
                fo: [2]
            },
            hide_dialogs: {formats: true},
            actionBtn: {
                to: '/releases/new',
                label: 'add new release',
                icon: 'mdi-plus-outline',
            },
            default_item_component: 'ItemPicture', //default view component defines the look of the list. Can be changed
            pictureListingLabel: 'label'
        }),
        component: () => import(/* webpackChunkName: "Releases" */ '../views/Releases.vue')
    },
    {
        path: '/releases/finnish_censorship',
        name: 'ReleasesCutFinnish',
        props: () => ({
            id: 'cut-vhs-releases',
            h1: 'cut finnish releases',
            subheader: 'cut finnish intro',
            meta_name: 'cut finnish releases',
            meta_description: 'cut finnish intro',
            search_placeholder: 'search from cut finnish releases',
            canonical: '/releases/finnish_censorship',
            query_params: {
                fi: ['cut'],
                so: 'cuts_amount_desc',
                co: [4]
            },
            hide_dialogs: {countries_ac:true, date_range:true},
            default_item_component: 'ItemPictureTextInfo', //default view component defines the look of the list. Can be changed
            pictureListingLabel: 'cut_seconds'
        }),
        component: () => import(/* webpackChunkName: "Releases" */ '../views/Releases.vue')
    },
    {
        path: '/releases/finnish_video_releases',
        name: 'ReleasesFinnish',
        props: () => ({
            id: 'fin-releases',
            h1: 'fin releases',
            subheader: 'finnish releases intro',
            meta_name: 'fin releases',
            meta_description: 'finnish releases intro',
            search_placeholder: 'search from all releases',
            canonical: '/releases/finnish_video_releases',
            query_params: {
                co: [4,96]
            },
            hide_dialogs: {countries_ac:true, date_range:true},
            default_item_component: 'ItemPictureTextInfo', //default view component defines the look of the list. Can be changed
            pictureListingLabel: 'cut_seconds'
        }),
        component: () => import(/* webpackChunkName: "Releases" */ '../views/Releases.vue')
    },

    {
        path: '/releases/upcoming',
        name: 'ReleasesUpcoming',
        component: () => import(/* webpackChunkName: "ReleasesUpcoming" */ '../views/ReleasesUpcoming.vue')
    },

    {
        path: '/movies',
        name: 'Movies',
        props: () => ({
            id: 'movies',
            h1: 'movies',
            meta_name: 'movies',
            meta_description: 'movies',
            search_placeholder: 'search movie name aka',
            query_params: {
                fi: ['movie']
            },
            hide_dialogs: {},
            canonical: '/movies',
            actionBtn: {
                to: '/movies/new',
                label: 'add new movie',
                icon: 'mdi-plus-outline',
            },
            default_item_component: 'ItemPicture', //default view component defines the look of the list. Can be changed
            pictureListingLabel: 'year'
        }),
        component: () => import(/* webpackChunkName: "Movies" */ '../views/Movies.vue')
    },
    {
        path: '/tv_shows',
        name: 'TvShows',
        props: () => ({
            id: 'tv_show',
            h1: 'tv shows',
            meta_name: 'tv shows',
            meta_description: 'tv shows',
            search_placeholder: 'search tv show',
            canonical: '/tv_shows',
            query_params: {
                fi: ['tv_series']
            },
            hide_dialogs: {},
            actionBtn: {
                to: '/movies/new',
                label: 'add new movie',
                icon: 'mdi-plus-outline',
            },
            default_item_component: 'ItemPicture', //default view component defines the look of the list. Can be changed
            pictureListingLabel: 'year'
        }),
        component: () => import(/* webpackChunkName: "Movies" */ '../views/Movies.vue')
    },

    {
        path: '/tv_episodes',
        name: 'TvEpisodes',
        props: () => ({
            id: 'tv_episode',
            h1: 'tv episodes',
            meta_name: 'tv episodes',
            meta_description: 'tv episodes',
            search_placeholder: 'search tv episode videospace',
            query_params: {
                fi: ['tv_episode']
            },
            canonical: '/tv_episodes',
            hide_dialogs: {},
            actionBtn: {
                to: '/movies/new',
                label: 'add new movie',
                icon: 'mdi-plus-outline',
            },
            default_item_component: 'ItemPictureTextInfo', //default view component defines the look of the list. Can be changed
            pictureListingLabel: 'name'
        }),
        component: () => import(/* webpackChunkName: "Movies" */ '../views/Movies.vue')
    },


    {
        path: '/movies/new',
        name: 'MovieNew',
        props: () => ({
            kill_keep_alive: Date.now(), //always reset this page when it is called
            meta_name: 'add new movie',
            meta_description: 'add new movie',
        }),
        component: () => import(/* webpackChunkName: "MovieNew" */  '../views/MovieNew.vue')
    },
    {
        path: '/releases/new',
        name: 'ReleaseNew',
        props: () => ({
            kill_keep_alive: Date.now(), //always reset this page when it is called
            meta_name: 'add new release',
            meta_description: 'add new release',
        }),
        component: () => import(/* webpackChunkName: "MovieNew" */  '../views/ReleaseNew.vue')
    },
    {
        path: '/movie/:public_id',
        name: 'Movie',
        props: (route) => ({
            baseUrl: 'movie',
            publicId: route.params.public_id,
            resourcePath: 'titles/',
            subPage: '',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Movie" */ '../views/Movie.vue')
    },
    {
        path: '/title/:public_id',
        beforeEnter: (to, from, next) => {
            // Perform a 301 (permanent) redirect
            next({ path: to.redirectedFrom, query: { status: 301 }, replace: true });
        },
        redirect: (to) => {
            const publicId = to.params.public_id;
            return `/movie/${publicId}`;
        },
        status: 301,
    },
    {
        path: '/movie/:public_id/contact',
        name: 'MovieContact',
        props: (route) => ({
            baseUrl: 'movie',
            publicId: route.params.public_id,
            resourcePath: 'titles/',
            subPage: '/contact',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Movie" */ '../views/Movie.vue')
    },
    {
        path: '/movie/:public_id/photos',
        name: 'MoviePhotos',
        props: (route) => ({
            baseUrl: 'movie',
            publicId: route.params.public_id,
            resourcePath: 'titles/',
            subPage: '/photos',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Movie" */ '../views/Movie.vue')
    },
    /* disabled for now
    {
        path: '/movie/:public_id/photos/new',
        name: 'MoviePhotoNew',
        props: (route) => ({
            parent_base: 'titles',
            parent_model: 'title',
            parent_id: route.params.public_id,
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "PhotoEditor" /  '../forms/PhotoEditor.vue')
    },
    */
    {
        path: '/movie/:public_id/releases',
        name: 'MovieReleases',
        props: (route) => ({
            baseUrl: 'movie',
            publicId: route.params.public_id,
            resourcePath: 'titles/',
            subPage: '/releases',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Movie" */ '../views/Movie.vue')
    },
    {
        path: '/movie/:public_id/releases/new',
        name: 'MovieReleaseNew',
        props: (route) => ({
            parent_base: 'titles',
            parent_id: route.params.public_id,
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "ReleaseNew" */  '../views/ReleaseNew.vue')
    },
    {
        path: '/movie/:public_id/videos',
        name: 'MovieVideos',
        props: (route) => ({
            baseUrl: 'movie',
            publicId: route.params.public_id,
            resourcePath: 'titles/',
            subPage: '/videos',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Movie" */ '../views/Movie.vue')
    },
    {
        path: '/movie/:public_id/videos/new',
        name: 'MovieVideoNew',
        props: (route) => ({
            parent_base: 'titles',
            parent_model: 'title',
            parent_id: route.params.public_id,
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "VideoEditor" */  '../views/VideoEditor.vue')
    },
    {
        path: '/movie/:public_id/buying_options',
        name: 'MovieBuyingOptions',
        props: (route) => ({
            baseUrl: 'movie',
            publicId: route.params.public_id,
            resourcePath: 'titles/',
            subPage: '/buying_options',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Movie" */ '../views/Movie.vue')
    },
    {
        path: '/movie/:public_id/posts',
        name: 'MoviePosts',
        props: (route) => ({
            baseUrl: 'movie',
            publicId: route.params.public_id,
            resourcePath: 'titles/',
            subPage: '/posts',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Movie" */ '../views/Movie.vue')
    },
    {
        path: '/movie/:public_id/posts/new',
        name: 'NewMoviePost',
        props: (route) => ({
            parent_model: 'titles',
            parent_id: route.params.public_id,
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "new-post" */ '../views/PostForm.vue')
    },
    {
        path: '/movie/:public_id/movie_connections',
        name: 'MovieConnections',
        props: (route) => ({
            baseUrl: 'movie',
            publicId: route.params.public_id,
            resourcePath: 'titles/',
            subPage: '/movie_connections',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Movie" */ '../views/Movie.vue')
    },
    {
        path: '/movie/:public_id/cast_crew',
        name: 'MovieCrew',
        props: (route) => ({
            baseUrl: 'movie',
            publicId: route.params.public_id,
            resourcePath: 'titles/',
            subPage: '/cast_crew',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Movie" */ '../views/Movie.vue')
    },
    {
        path: '/movie/:public_id/reviews',
        name: 'MovieReviews',
        props: (route) => ({
            baseUrl: 'movie',
            publicId: route.params.public_id,
            resourcePath: 'titles/',
            subPage: '/reviews',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Movie" */ '../views/Movie.vue')
    },
    {
        path: '/movie/:public_id/reviews/new',
        name: 'NewMovieReview',
        props: (route) => ({
            parent_model: 'title',
            parent_id: route.params.public_id,
            kill_keep_alive: Date.now(),
        }),
        component: () => import(/* webpackChunkName: "new-review" */ '../views/ReviewForm.vue')
    },
    {
        path: '/movie/:public_id/user_collections',
        name: 'MovieUserCollections',
        props: (route) => ({
            baseUrl: 'movie',
            publicId: route.params.public_id,
            resourcePath: 'titles/',
            subPage: '/user_collections',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Movie" */ '../views/Movie.vue')
    },
    {
        path: '/movie/:public_id/edit',
        name: 'MovieEditor',
        props: (route) => ({
            public_id: route.params.public_id,
            page: 'main',
            kill_keep_alive: Date.now(),
            meta_name: 'update movie'
        }),
        component: () => import(/* webpackChunkName: "MovieEditor" */ '../views/MovieEditor.vue')
    },
    {
        path: '/movie/:public_id/edit/crew',
        name: 'MovieEditorCrew',
        props: (route) => ({
            public_id: route.params.public_id,
            page: 'crew',
            kill_keep_alive: Date.now(),
        }),
        component: () => import(/* webpackChunkName: "MovieEditorCrew" */ '../views/MovieEditor.vue')
    },
    {
        path: '/movie/:public_id/edit/connections',
        name: 'MovieEditorConnections',
        props: (route) => ({
            public_id: route.params.public_id,
            page: 'connections',
            kill_keep_alive: Date.now(),
        }),
        component: () => import(/* webpackChunkName: "MovieEditorConnections" */ '../views/MovieEditor.vue')
    },
    {
        path: '/movie/:public_id/history',
        name: 'MovieHistory',
        props: (route) => ({
            baseUrl: 'movie',
            publicId: route.params.public_id,
            resourcePath: 'titles/',
            subPage: '/history',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Movie" */ '../views/Movie.vue')
    },
    //Release routes:
    {
        path: '/release/:public_id',
        name: 'Release',
        props: (route) => ({
            baseUrl: 'release',
            publicId: route.params.public_id,
            resourcePath: 'releases/',
            subPage: '',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Release" */ '../views/Release.vue')
    },
    {
        path: '/release/:public_id/edit',
        name: 'ReleaseEditor',
        props: (route) => ({
            public_id: route.params.public_id,
            kill_keep_alive: Date.now(),
        }),
        component: () => import(/* webpackChunkName: "ReleaseEditor" */ '../views/ReleaseEditor.vue')
    },
    {
        path: '/release/:public_id/duplicate',
        name: 'ReleaseDuplicator',
        props: (route) => ({
            public_id: route.params.public_id,
            duplicate: route.params.public_id,
        }),
        component: () => import(/* webpackChunkName: "ReleaseEditor" */ '../views/ReleaseEditor.vue')
    },
    {
        path: '/release/:public_id/other_releases',
        name: 'OtherReleases',
        props: (route) => ({
            baseUrl: 'release',
            publicId: route.params.public_id,
            resourcePath: 'releases/',
            subPage: '/other_releases',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Release" */ '../views/Release.vue')
    },
    {
        path: '/release/:public_id/movies',
        name: 'ReleaseMovies',
        props: (route) => ({
            baseUrl: 'release',
            publicId: route.params.public_id,
            resourcePath: 'releases/',
            subPage: '/movies',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Release" */ '../views/Release.vue')
    },
    {
        path: '/release/:public_id/photos',
        name: 'ReleasePhotos',
        props: (route) => ({
            baseUrl: 'release',
            publicId: route.params.public_id,
            resourcePath: 'releases/',
            subPage: '/photos',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Release" */ '../views/Release.vue')
    },
    {
        path: '/release/:public_id/photos/new',
        name: 'ReleasePhotoNew',
        props: (route) => ({
            parent_base: 'releases',
            parent_model: 'release',
            parent_id: route.params.public_id,
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "PhotoEditor" */  '../forms/PhotoEditor.vue')
    },
    {
        path: '/release/:public_id/videos',
        name: 'ReleaseVideos',
        props: (route) => ({
            baseUrl: 'release',
            publicId: route.params.public_id,
            resourcePath: 'releases/',
            subPage: '/videos',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Release" */ '../views/Release.vue')
    },
    {
        path: '/release/:public_id/videos/new',
        name: 'ReleaseVideoNew',
        props: (route) => ({
            parent_base: 'releases',
            parent_model: 'release',
            parent_id: route.params.public_id,
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "VideoEditor" */  '../views/VideoEditor.vue')
    },
    {
        path: '/release/:public_id/user_collections',
        name: 'ReleaseUserCollections',
        props: (route) => ({
            baseUrl: 'release',
            publicId: route.params.public_id,
            resourcePath: 'releases/',
            subPage: '/user_collections',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Release" */ '../views/Release.vue')
    },
    {
        path: '/release/:public_id/buying_options',
        name: 'ReleaseBuyingOptions',
        props: (route) => ({
            baseUrl: 'release',
            publicId: route.params.public_id,
            resourcePath: 'releases/',
            subPage: '/buying_options',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Release" */ '../views/Release.vue')
    },
    {
        path: '/release/:public_id/posts',
        name: 'ReleasePosts',
        props: (route) => ({
            baseUrl: 'release',
            publicId: route.params.public_id,
            resourcePath: 'releases/',
            subPage: '/posts',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Release" */ '../views/Release.vue')
    },
    {
        path: '/release/:public_id/posts/new',
        name: 'NewReleasePost',
        props: (route) => ({
            parent_model: 'releases',
            parent_id: route.params.public_id,
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "new-post" */ '../views/PostForm.vue')
    },
    {
        path: '/release/:public_id/reviews',
        name: 'ReleaseReviews',
        props: (route) => ({
            baseUrl: 'release',
            publicId: route.params.public_id,
            resourcePath: 'releases/',
            subPage: '/reviews',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Release" */ '../views/Release.vue')
    },
    {
        path: '/release/:public_id/reviews/new',
        name: 'NewReleaseReview',
        props: (route) => ({
            parent_model: 'release',
            parent_id: route.params.public_id,
            kill_keep_alive: Date.now(),
        }),
        component: () => import(/* webpackChunkName: "new-review" */ '../views/ReviewForm.vue')
    },
    {
        path: '/release/:public_id/contact',
        name: 'ReleaseContact',
        props: (route) => ({
            baseUrl: 'release',
            publicId: route.params.public_id,
            resourcePath: 'releases/',
            subPage: '/contact',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Release" */ '../views/Release.vue')
    },
    {
        path: '/release/:public_id/history',
        name: 'ReleaseHistory',
        props: (route) => ({
            baseUrl: 'release',
            publicId: route.params.public_id,
            resourcePath: 'releases/',
            subPage: '/history',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Release" */ '../views/Release.vue')
    },
    {
        path: '/persons',
        name: 'Persons',
        props: () => ({
            id: 'persons',
            h1: 'cast crew',
            meta_name: 'cast crew',
            meta_description: 'cast crew',
            search_placeholder: 'search',
            query_params: {so: 'id_desc'},
            hide_dialogs: {},
            default_item_component: 'ItemPicture', //default view component defines the look of the list. Can be changed
            pictureListingLabel: 'name'
        }),
        component: () => import(/* webpackChunkName: "Persons" */ '../views/Persons.vue')
    },
    {
        path: '/person/:public_id',
        name: 'Person',
        //props: (route) => ({ public_id: route.params.public_id, base_url: 'person', api_base: 'person_movies', model: 'Title' }),
        props: (route) => ({
            baseUrl: 'person',
            publicId: route.params.public_id,
            resourcePath: 'persons/',
            subPage: '',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace'
        }),
        component: () => import(/* webpackChunkName: "Person" */ '../views/Person.vue')
    },
    {
        path: '/person/:public_id/releases',
        name: 'PersonReleases',
        //props: (route) => ({ public_id: route.params.public_id, base_url: 'person', api_base: 'person_movies', model: 'Title' }),
        props: (route) => ({
            baseUrl: 'person',
            publicId: route.params.public_id,
            resourcePath: 'persons/',
            subPage: '/releases',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Person" */ '../views/Person.vue')
    },
    {
        path: '/person/:public_id/edit',
        name: 'PersonEditor',
        props: (route) => ({
            publicId: route.params.public_id,
            resourcePath: 'persons/',
            storePath: 'persons',
            kill_keep_alive: Date.now(),
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "PersonEditor" */ '../views/PersonEditor.vue')
    },
    {
        path: '/persons/new',
        name: 'PersonNew',
        props: () => ({
            kill_keep_alive: 'aaa'
        }),
        component: () => import(/* webpackChunkName: "PersonEditor" */ '../views/PersonEditor.vue')
    },
    {
        path: '/productionCompany/:public_id',
        redirect: (to) => {
            const publicId = to.params.public_id;
            return `/company/${publicId}`;
        },
        beforeEnter: (to, from, next) => {
            // Perform a 301 (permanent) redirect
            next({ path: to.redirectedFrom, query: { status: 301 }, replace: true });
        },
    },
    {
        path: '/company/:public_id',
        name: 'Company',
        props: (route) => ({
            baseUrl: 'company',
            publicId: route.params.public_id,
            resourcePath: 'production_companies/',
            subPage: '',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Company" */ '../views/Company.vue')
    },
    {
        path: '/collections',
        name: 'Collections',
        props: () => ({
            id: 'collections',
            h1: 'collections',
            meta_name: 'collections',
            meta_description: 'collections',
            search_placeholder: 'search collections',
            query_params: {},
            hide_dialogs: {},
            default_item_component: 'ItemPictureTextInfo', //default view component defines the look of the list. Can be changed
        }),
        component: () => import(/* webpackChunkName: "UserCollections" */ '../views/UserCollections.vue')
    },
    {
        path: '/collection/:public_id',
        name: 'UserCollection',
        props: (route) => ({
            baseUrl: 'collection',
            publicId: route.params.public_id,
            resourcePath: 'collections/',
            subPage: '',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "UserCollection" */ '../views/UserCollection.vue')
    },
    {
        path: '/collection/:public_id/edit',
        name: 'CollectionEditor',
        props: (route) => ({
            publicId: route.params.public_id,
            resourcePath: 'collections/',
            storePath: 'collections',
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "CollectionEditor" */ '../views/CollectionEditor.vue')
    },
    {
        path: '/collections/new',
        name: 'CollectionNew',
        props: () => ({
            resourcePath: 'collections',
            storePath: 'collections',
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "CollectionEditor" */ '../views/CollectionEditor.vue')
    },
    {
        path: '/users',
        name: 'Users',
        props: () => ({
            id: 'users',
            h1: 'users',
            meta_name: 'users',
            meta_description: 'users',
            search_placeholder: 'search user',
            query_params: {so: 'id_desc', fi: 'yes_picture'},
            hide_dialogs: {},
            default_item_component: 'ItemPicture', //default view component defines the look of the list. Can be changed
            pictureListingLabel: 'name'
        }),
        component: () => import(/* webpackChunkName: "Users" */ '../views/Users.vue')
    },
    {
        path: '/user/:public_id',
        name: 'User',
        props: (route) => ({
            baseUrl: 'user',
            publicId: route.params.public_id,
            resourcePath: 'users/',
            subPage: '',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "User" */ '../views/User.vue')
    },
    {
        path: '/user/:public_id/collections',
        name: 'UserCollectionsUser',
        props: (route) => ({
            baseUrl: 'user',
            publicId: route.params.public_id,
            resourcePath: 'users/',
            subPage: '/collections',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "User" */ '../views/User.vue')
    },
    {
        path: '/user/:public_id/contributions',
        name: 'UserContributions',
        props: (route) => ({
            baseUrl: 'user',
            publicId: route.params.public_id,
            resourcePath: 'users/',
            subPage: '/contributions',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "User" */ '../views/User.vue')
    },
    {
        path: '/user/:public_id/likes',
        name: 'UserLikes',
        props: (route) => ({
            baseUrl: 'user',
            publicId: route.params.public_id,
            resourcePath: 'users/',
            subPage: '/likes',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "User" */ '../views/User.vue')
    },
    {
        path: '/user/:public_id/posts',
        name: 'UserPosts',
        props: (route) => ({
            baseUrl: 'user',
            publicId: route.params.public_id,
            resourcePath: 'users/',
            subPage: '/posts',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "User" */ '../views/User.vue')
    },
    {
        path: '/user/:public_id/reviews',
        name: 'UserReviews',
        props: (route) => ({
            baseUrl: 'user',
            publicId: route.params.public_id,
            resourcePath: 'users/',
            subPage: '/reviews',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "User" */ '../views/User.vue')
    },
    {
        path: '/user/:public_id/edit',
        redirect: '/users/edit_my_data',
    },
    {
        path: '/users/edit_my_data',
        name: 'EditAuthUser',
        props: () => ({
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "EditAuthUser" */ '../views/UserEditor.vue')
    },

    {
        path: '/posts',
        name: 'Posts',
        props: () => ({
            id: 'posts',
            h1: 'discussions',
            meta_name: 'discussions',
            meta_description: 'discussions',
            search_placeholder: 'search for discussion',
            query_params: {},
            hide_dialogs: {},
            default_item_component: 'ItemPictureTextInfo', //default view component defines the look of the list. Can be changed
        }),
        component: () => import(/* webpackChunkName: "Discussions" */ '../views/Discussions.vue')
    },
    {
        path: '/posts/new',
        name: 'NewPost',
        props: () => ({
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "new-post" */ '../views/PostForm.vue')
    },
    {
        path: '/post/:public_id',
        name: 'Post',
        props: (route) => ({
            baseUrl: 'post',
            publicId: route.params.public_id,
            resourcePath: 'posts/',
            subPage: '',
            pre_meta_name: 'videospace',
            pre_meta_description: 'videospace',
        }),
        component: () => import(/* webpackChunkName: "Post" */ '../views/Post.vue')

    },
    {
        path: '/post/:public_id/edit',
        name: 'EditPost',
        props: (route) => ({
            public_id: route.params.public_id,
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "new-post" */ '../views/PostForm.vue')
    },
    {
        path: '/reviews',
        name: 'Reviews',
        props: () => ({
            id: 'all-reviews',
            apiRoute: "reviews",
            h1: 'reviews',
            meta_name: 'reviews',
            meta_description: 'reviews',
            search_placeholder: 'search for review',
            query_params: {},
            hide_dialogs: {},
            actionBtn: {
                to: '/reviews/new',
                label: 'new review',
                icon: 'mdi-plus-outline',
            },
            buyingOptions: {
                campaignIds: {amazon: 'release_b'}
            },
            default_item_component: 'ItemPictureTextInfo', //default view component defines the look of the list. Can be changed
            searchResources: 'review',
            pictureListingLabel: 'rating'

        }),
        component: () => import(/* webpackChunkName: "Reviews" */ '../views/Reviews.vue')
    },
    {
        path: '/reviews/new',
        name: 'NewReview',
        props: () => ({
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "new-review" */ '../views/ReviewForm.vue')
    },
    {
        path: '/review/:public_id',
        name: 'Review',
        props: (route) => ({
            id: 'review',
            baseUrl: 'review',
            publicId: route.params.public_id,
            resourcePath: 'reviews/',
            subPage: '',
            pre_meta_name: 'videospace',
            pre_meta_description: 'review',
        }),
        component: () => import(/* webpackChunkName: "review" */ '../views/Review.vue')
    },
    {
        path: '/review/:public_id/edit',
        name: 'EditReview',
        props: (route) => ({
            public_id: route.params.public_id,
            kill_keep_alive: Date.now(),
        }),
        component: () => import(/* webpackChunkName: "new-review" */ '../views/ReviewForm.vue')
    },
    {
        path: '/review/:public_id/posts',
        name: 'ReviewPosts',
        props: (route) => ({
            id: 'review',
            baseUrl: 'review',
            publicId: route.params.public_id,
            resourcePath: 'reviews/',
            subPage: '/posts',
            pre_meta_name: 'videospace',
            pre_meta_description: 'review',
        }),
        component: () => import(/* webpackChunkName: "review" */ '../views/Review.vue')
    },
    {
        path: '/review/:public_id/posts/new',
        name: 'NewPostReview',
        props: (route) => ({
            parent_model: 'reviews',
            parent_id: route.params.public_id,
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "new-post" */ '../views/PostForm.vue')
    },

    //photos edit
    {
        path: '/photo/:public_id/edit',
        name: 'EditPhoto',
        props: (route) => ({
            public_id: route.params.public_id,
            kill_keep_alive: Date.now(),
        }),
        component: () => import(/* webpackChunkName: "PhotoEditor" */ '../forms/PhotoEditor.vue')
    },

    //videos edit
    {
        path: '/video/:public_id/edit',
        name: 'EditVideo',
        props: (route) => ({
            public_id: route.params.public_id,
            kill_keep_alive: Date.now(),
        }),
        component: () => import(/* webpackChunkName: "VideoEditor" */ '../views/VideoEditor.vue')
    },

    //admin routes
    {
        path: '/admin/changes',
        name: 'AdminChanges',
        props: () => ({
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "AdminChanges" */ '../views/AdminChanges.vue')
    },

    /*
    text based collection of labels.

     */
    {
        path: '/labels',
        name: 'Labels',
        props: () => ({
            id: 'labels',
            h1: 'labels',
            apiRoute: 'labels_view',
            meta_name: 'labels',
            meta_description: 'labels',
            search_placeholder: 'search for label',
            query_params: {},
            hide_dialogs: {},
            actionBtn: {
                to: '/labels/new',
                label: 'add new label',
                icon: 'mdi-plus-outline',
            },
            buyingOptions: {
              campaignIds: {amazon: 'release_b'}
            },
            default_item_component: 'ItemPictureTextInfo', //default view component defines the look of the list. Can be changed
            searchResources: 'label'
        }),
        component: () => import(/* webpackChunkName: "ListView" */ '../views/ListView.vue')
    },
    {
        path: '/label/:public_id',
        name: 'Label',
        props: (route) => ({
            baseUrl: 'label',
            publicId: route.params.public_id,
            resourcePath: 'labels/',
            //resourceParams: {with_label_label_groups: '1'}, //using this will activate useApiResource get each time when any parameter is changed
            subPage: '/',
            pre_meta_name: 'videospace',
            pre_meta_description: 'home video label',
        }),
        component: () => import(/* webpackChunkName: "LabelView" */ '../views/LabelView.vue')
    },
    {
        path: '/labels/new',
        name: 'LabelEditorNew',
        props: (route) => ({
            baseUrl: 'label',
            publicId: route.params.public_id,
            resourcePath: 'labels/',
            //resourceParams: {with_label_label_groups: '1'}, //using this will activate useApiResource get each time when any parameter is changed
            subPage: '/',
            pre_meta_name: 'videospace',
            pre_meta_description: 'home video label',

        }),
        component: () => import(/* webpackChunkName: "LabelView" */ '../views/LabelView.vue')
    },
    {
        path: '/label/:public_id/edit',
        name: 'LabelEditor',
        props: (route) => ({
            public_id: route.params.public_id,
            standalone: true,
            kill_keep_alive: Date.now(), //always reset this page when it is called
        }),
        component: () => import(/* webpackChunkName: "LabelEditor" */ '../views/LabelEditor.vue')
    },
    {
        path: '/label/:public_id/groups',
        name: 'LabelGroups',
    },

    //register
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "Register" */ '../views/Register.vue')
    },
    //lost password form
    {
        path: '/reset_password',
        name: 'ResetPass',
        component: () => import(/* webpackChunkName: "RedeemPass" */ '../views/RedeemPass.vue')
    },

    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component:  () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy.vue')
    },
    {
        path: '/service-info',
        name: 'ServiceInfo',
        component:  () => import(/* webpackChunkName: "ServiceInfo" */ '../views/ServiceInfo.vue')
    },
    {
        path: '/terms-of-service',
        name: 'TermsOfService',
        component:  () => import(/* webpackChunkName: "TermsOfService" */ '../views/TermsOfService.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        props: () => ({
            meta_name: 'contact us',
            meta_description: 'contact us'
        }),
        component:  () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
    },
    {
        path: '/404',
        name: 'NotFound',
        status: 404,
        component:  () => import(/* webpackChunkName: "misc" */ '../views/NotFound.vue')
    },

    {
        path: '/:catchAll(.*)',
        redirect: '/404',
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {

        if(savedPosition && savedPosition.y)
            return { x: 0, y: savedPosition.y }
            else
            return { x: 0, y: 0 }
    }
})

export default router
