import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import cookie from './plugins/cookie'
import i18n from './locales/i18n'
//import './plugins/understand' //disabled for now
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
    // Define your global default title and meta tags here
    // These will be used when components don't specify their own values
    refreshOnceOnNavigation: true,
    defaultTitle: 'Videospace',
    defaultTitleTemplate: '%s | Videospace',
    defaultMetaInfo: {
        // Default meta tags for description, keywords, etc.
        description: 'Videospace',
        // Add more default meta tags as needed
    },
})
import VueGtag from 'vue-gtag'
Vue.use(VueGtag, {
    config: { id: "G-QPK97LVT1P" }
});

//universal social stuff:
import axios from 'axios'
import UniversalSocialauth from "universal-social-auth";
const options = {
    providers: {
        /*
        apple: {
            nonce: '**************',
            state: '**************',
            clientId: '**************',
            responseMode: 'form_post',
            response_type: 'code  id_token',
            optionalUrlParams: ['response_mode', 'use_popup', 'state'],
            usePopup: true,
            redirectUri: 'https://myapp.com/auth/github/callback'
        },
        github: {
            clientId: '**************',
            redirectUri: 'https://myapp.com/auth/github/callback'
        },
        twitter: {
            url: 'https://myapp.com/auth/twitter',
            clientId: '********',
            redirectUri: 'https://myapp.com/auth/twitter/callback'
        }
                google: {
            clientId: '485206584970-0h3301aplskhhc9emg5q289ico7aff9a.apps.googleusercontent.com',
            redirectUri: 'http://localhost:8080',
        },
        facebook: {
            clientId: '468225946704845',
            redirectUri: 'http://localhost:8080',
        },

         */
        google: {
            clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            redirectUri: process.env.VUE_APP_GOOGLE_REDIRECT,
        },
        facebook: {
            url: '/auth/facebook',
            authorizationEndpoint: 'https://www.facebook.com/v2.5/dialog/oauth',
            clientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
            redirectUri: process.env.VUE_APP_FACEBOOK_REDIRECT,
        },
    }
}
const Oauth = new UniversalSocialauth(axios, options)


import './plugins/dayjs'
Vue.config.productionTip = false
/* without Gtag:
router.afterEach((to) => {
    window.gtag('config', window.GA_TRACKING_ID, {
        page_path: to.fullPath,
        app_name: 'Videospace app',
        send_page_view: true,
    });
});
*/
new Vue({
    router,
    store,
    vuetify,
    cookie,
    i18n,
    render: h => h(App)
}).$mount('#app')
Vue.prototype.$Oauth = Oauth
