import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      auth_user: null,
      auth_user_api_call: true,
      reset_label: false,
      reset_release: false,
      reset_title: false,
      reset_person: false,
      reset_review: false,
      reset_user: false,
      reset_collection: false,
  },
  mutations: {
      authUser (state, value) {
          state.auth_user = value
      },
      authUserApiCall (state, value) {
          state.auth_user_api_call = value
      },
      resetLabel (state, value) {
          state.reset_label = value
      },
      resetRelease (state, value) {
          state.reset_release = value
      },
      resetTitle (state, value) {
          state.reset_title = value
      },
      resetPerson (state, value) {
          state.reset_person = value
      },
      resetReview (state, value) {
          state.reset_review = value
      },
      resetUser (state, value) {
          state.reset_user = value
      },
      resetCollection (state, value) {
          state.reset_collection = value
      },
  },
  actions: {
  },
  modules: {
  }
})
