
export default {
    data: function () {
        return {
            api_call: false,
            selected_drawer_state: false, //is menu open or not
            error: null,
            item_call: false, //are we calling the main item?
        }

    },
    props: [
        'pre_meta_name',
        'pre_meta_description',
    ],
    computed: {

        //meta name
        metaName(){
            let r
            if(this.pre_meta_name) r = this.pre_meta_name
            return r
        },

        //description
        metaDescription(){
            let r
            if(this.pre_meta_description) r = this.pre_meta_description
            return r
        },

        //return true or false based of current vuetify breakpoint
        drawerResponsive() {

            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return false
                case 'sm':
                    return false
                case 'md':
                    return true
                case 'lg':
                    return true
                case 'xl':
                    return true

                default:
                    return true
            }
        },

        //return authored user
        auth_user(){

            if(this.$store.state.auth_user) return this.$store.state.auth_user

        },

        //return authored user api call
        auth_user_api_call(){

            if(this.$store.state.auth_user_api_call) return this.$store.state.auth_user_api_call
                else
                    return false

        },

        //return chat_client_id (user_id)
        auth_user_id(){

            if(this.$store.state.auth_user) return this.$store.state.auth_user.id

        },


        /**
         * find if there are any route parameters.
         *
         * @return bool
         */
        anyRouteParameters(){

            var bool = false

            if(Object.keys(this.$route.query).length > 0)
                Object.keys(this.$route.query).forEach(key => {if(this.$route.query[key].length > 0) bool = true})

            return bool
        }
    },
    methods: {

        getApi(){
            console.log('get api')
            this.api_call = 'item'

            this.axios.get('/'+this.api+'/'+this.public_id).then((result) => {
                this.item = result.data
                this.api_call = false
            }).catch(()=>{
                this.api_call = false
                this.error = this.$t('404 not found')
            })
        },

        base(model){
            if(model.toLowerCase() == 'title') return 'movie'
            else return model.toLowerCase()
        },

        secondsToMinutes(seconds){
            return new Date(seconds * 1000).toISOString().substr(14, 5)
        },

        //print out names of given array of objects. Used in flag title
        namesStr(obj){

            var str = ''

            obj.forEach(value=>{str += value.name+', '})

            return str.substring(0, str.length - 2)
        },

        //display date
        momentFormat(date, format = 'DD/MM/YYYY'){

            return this.$day(date).format(format)

        },

        //to get an api resource. Used in menu components. Is it deprecated?
        resourceHandler(resource, appendix = ''){

            this.api_call = true
            this.axios.get(resource+appendix)
                .then(response => {
                    this[resource] = response.data
                    this.api_call = false
                })
                .catch(e => {
                    console.log(e)
                    this.api_call = false
                })

        },

        //toggle something in given array
        toggleArray(name, value){

            var index = this[name].indexOf(value);

            if (index === -1) {
                this[name].push(value);
            } else {
                this[name].splice(index, 1);
            }
        },

        //remove something in one dimensional array
        removeFromArray(name, id){

            this[name].splice(this[name].findIndex(x => x == id), 1)

        },

    }
}
